<template>
  <div>
    <el-menu
      :default-active="onRoutes"
      :collapse="isCollapse"
      text-color="#fff"
      :unique-opened="true"
      router
    >
      <div>
        <svg-icon icon-class="logo" class="logo"></svg-icon>
      </div>
      <div v-for="(title, i) in menuList" :key="title.system_id">
        <template v-if="title.child">
          <el-submenu :key="i" :index="title.system_id + ''">
            <template slot="title">
              {{ title.name }}
            </template>
            <el-menu-item
              v-for="children in title.child"
              :key="children.path"
              :index="children.path"
            >
              {{ children.name }}
            </el-menu-item>
          </el-submenu>
        </template>
        <template v-else>
          <el-menu-item :index="title.path">
            <template slot="title">
              {{ title.name }}
            </template>
          </el-menu-item>
        </template>
      </div>
    </el-menu>
    <p class="version">Version: {{ $store.getters.version }}</p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'v-sidebar',
  data() {
    return {
      menuList: [
        {
          system_id: 2,
          name: '問卷列表',
          path: '/questionnaire-list',
        },
        {
          system_id: 3,
          name: '常見QA',
          path: '/qa',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['sidebar']),
    onRoutes() {
      return this.$route.path.replace('', '');
    },
    isCollapse() {
      return !this.sidebar.opened;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-menu {
  overflow-y: scroll;
}

.el-menu::-webkit-scrollbar {
  display: none;
}

.el-submenu {
  font-family: PingFangTC-Regular !important;
  font-size: 14px;
}

/deep/.el-menu-item {
  font-family: PingFangTC-Regular;
  font-size: 14px;
}

.goHome {
  background-color: #087cb1;
}
.version {
  color: rgb(190, 190, 190);
}
</style>
