<template>
  <div id="header">
    <div>
      <div @click="toggleSideBar" class="hamburger-container">
        <i :class="isFold"></i>
      </div>
    </div>
    <el-breadcrumb separator-class="el-icon-minus">
      <el-breadcrumb-item v-for="item in breadcrumbList" :key="item.path">
        {{ item.name }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <el-dropdown trigger="click" @command="handleCommand">
      <span class="el-dropdown-link">
        {{ user_name }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="userProfile">我的帳戶</el-dropdown-item>
        <!-- <el-dropdown-item command="resetPassword">重設密碼</el-dropdown-item> -->
        <el-dropdown-item command="logout">登出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'v-header',
  data() {
    return {
      breadcrumbList: [],
    };
  },
  created() {
    this.getBreadcrumb();
  },
  computed: {
    ...mapGetters(['sidebar']),
    isFold() {
      return this.sidebar.opened ? 'el-icon-s-fold' : 'el-icon-s-unfold';
    },
    user_name() {
      return this.$store.getters.userName || sessionStorage.getItem('userName');
    },
  },
  methods: {
    ...mapActions(['doLogout']),
    handleCommand(command) {
      switch (command) {
        case 'logout':
          this.logOut();
          break;
        case 'resetPassword':
          this.resetPassword();
          break;
        case 'userProfile':
          this.$router.push('/user-profile');
          break;
      }
    },
    logOut() {
      this.$confirm('請確認是否登出', '登出', {
        confirmButtonText: '確認',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.doLogout().then(() => {
            this.$message.success('登出成功');
          });
        })
        .catch((e) => e);
    },
    resetPassword() {
      this.$router.push('/permission/reset-password');
    },
    handleClickOutside() {
      this.$store.dispatch('closeSideBar', { withoutAnimation: false });
    },
    toggleSideBar() {
      this.$store.dispatch('toggleSideBar');
    },
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name);
      // this.$route.path.split('/')
      this.breadcrumbList = matched;
    },
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
};
</script>

<style scoped type="text/scss">
#header {
  background-color: #fff;
  box-shadow: 0 0 4px 3px #d9d9d9;
  color: #4a4a4a;
  height: 60px;
  display: flex;
  justify-content: space-between;
  padding: 0 18px;
  align-items: center;
}

.hamburger {
  width: 20px;
  height: 20px;
}

.hamburger-container {
  height: 100%;
  cursor: pointer;
  transition: background 0.3s;
  -webkit-tap-highlight-color: transparent;
  font-size: 24px;
}

i:hover {
  background: rgba(0, 0, 0, 0.025);
}

.el-breadcrumb {
  left: 50px;
  position: absolute;
  font-family: PingFangTC-Regular;
  font-size: 18px;
}

.el-breadcrumb__inner {
  color: #4a4a4a;
}

#header .el-dropdown {
  padding-right: 29px;
  font-size: 20px;
  color: #000000;
}
</style>
